import * as React from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import CardActionArea from '@mui/material/CardActionArea'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Modal from '@mui/material/Modal'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import BackgroundVideo from './BackgroundVideo'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'

const youtubeMiniParams = '?rel=0&autoplay=0&playsinline=1&modestbranding=1&controls=1'

const galleryCards = [
  {
    heading: 'Requiem',
    brief: '2022 NYC Film Invitational Claustrophobic Creativity Challenge Submission',
    thumbnail: 'assets/requiem thumb.jpg',
    videoUrl: 'https://www.youtube.com/embed/5DWs5yuro0Y',
    longDescription: 'This was my groups submission into the 2022 All American High School Film Festival. We only had a few days to film and edit this project, booking real actors and sets in New York. I was the editor on this project.',
    category: 'competition',
  },
  {
    heading: 'ASCTE Promo for Firia Labs',
    brief: 'Client requested a fast-paced recap video showcasing a group of student beta-testers in action.',
    thumbnail: 'assets/AscteCover.jpg',
    videoUrl: 'https://www.youtube.com/embed/hi28aBD_jJ0',
    longDescription: 'Firia Labs is a local edtech company for whom I had done a number of past social-media focused projects. In this case they had a group of students beta-testing their products over the summer. The project required me to spend time onsite across a period of weeks, capturing and producing the promo video.',
    category: 'corp media',
  },
  {
    heading: 'Lord D',
    brief: 'A fun project for Intro to Film class',
    thumbnail: 'assets/lord d thumb.jpg',
    videoUrl: 'https://www.youtube.com/embed/LurgkiQmbIY',
    longDescription: 'Made this "masterpiece" in my intro to film class and had entirely too much fun with it!',
    category: 'school project',
  },
  {
    heading: 'Headphones',
    brief: 'A micro-film  that I directed/edited in film class. Really fun to make.',
    thumbnail: 'assets/headphones thumb.jpg',
    videoUrl: 'https://www.youtube.com/embed/EzDFTl6mIEQ',
    longDescription: '',
    category: 'school project',
  },
  {
    heading: 'Elden Ring - News Segment',
    brief: 'This was an assignment in my intro to film class in 2021. It won the class award for best news segment editing!',
    thumbnail: 'assets/elden ring thumb.jpg',
    videoUrl: 'https://www.youtube.com/embed/3Xt5RwNSqe0',
    longDescription: 'This was an assignment in my intro to film class in 2021. It won the class award for best news segment editing!',
    category: 'school project',
  },
  {
    heading: 'Dogwood Lung : Skin Against Carpet',
    brief: 'Music Video',
    thumbnail: 'assets/sag mv thumb.jpg',
    videoUrl: 'https://www.youtube.com/embed/cxONh4-xGQI',
    longDescription: 'This was made as a solo project for my intro to film class in 2021! Tons of fun to film / edit. The band has given me full rights to use this song here.',
    category: 'school project',
  },
  {
    heading: 'Highlight Reel',
    brief: 'Showcasing some of my work, soundtracked.',
    thumbnail: 'assets/reel thumb.jpg',
    videoUrl: 'https://www.youtube.com/embed/X41C3SxpYmM',
    longDescription: '',
    category: 'personal',
  },
]

const videoModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

export default function Album() {
  const [focusVideoUrl, setFocusVideoUrl] = React.useState('')
  const [showCardDetail, setShowCardDetail] = React.useState(-1)
  const theme = useTheme()
  const matchesSmallScreens = useMediaQuery(theme.breakpoints.down('md'))
  const matchesLandscape = useMediaQuery('(min-aspect-ratio: 3/2)')
  const videoEl = React.createRef<HTMLVideoElement>()
  const [isMuted, setIsMuted] = React.useState(true)

  React.useEffect(() => {
    // Register an observer to mute video when user scrolls below the fold
    const target = document.querySelector('#about-anchor')

    const observer = new IntersectionObserver(
      (entries, opts) => {
        const isIntersecting = entries?.[0].isIntersecting // N% of #about-anchor is visible
        // console.log('callback')

        if (isIntersecting && videoEl.current) {
          // console.log('intersecting')
          videoEl.current.muted = true
          setIsMuted(true)
        }
      },
      {
        root: null,   // default is the viewport
        threshold: matchesSmallScreens ? 0.5 : 0.9, // % target's visible area before triggering callback.
      }
    )
    // console.log('observe')
    target && observer.observe(target)

    return () => {
      // console.log('unobserve')
      target && observer.unobserve(target)
    }
  }, [videoEl, matchesSmallScreens])

  return (
    <main>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Container maxWidth={false} disableGutters={true}>
          <div style={{ position: 'relative', overflow: 'hidden' }}>
            <BackgroundVideo
              ref={videoEl}
              vidUrl={
                // Smaller (rf30 compress level) for mobile. Tag 'mobile' is portrait format.
                matchesSmallScreens ? (matchesLandscape ? 'assets/Jan2023Reel-sm_rf30.mp4#t=4' : 'assets/reel mobile-sm_rf30.mp4') :
                  'assets/Jan2023Reel-sm.mp4#t=4'
              }
            />
            <div style={{ position: 'absolute', top: '0', left: '0', background: 'rgba(0, 0, 0, 0.5)', width: '100%' }}>
              <Tooltip title={isMuted ? 'Let the sounds fly!' : 'Hush now, dear.'} placement='left'>
                <IconButton
                  sx={{ float: 'right', mr: '1em', mt: '3px' }}
                  onClick={() => {
                    if (videoEl.current) {
                      videoEl.current.muted = !isMuted
                      setIsMuted(!isMuted)
                    }
                  }}
                >
                  {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                </IconButton>
              </Tooltip>
              <Typography
                component='h1'
                variant='h2'
                align='left'
                color='text.primary'
                sx={{ mx: '10px', fontWeight: 700 }}
              >
                vid·e·o
              </Typography>
              <Typography variant='body1' align='left' color='text.primary' paragraph
                sx={{ ml: '2em' }}
              >
                /ˈvidēō/
              </Typography>
              <Typography variant='body1' align='left' color='text.primary' paragraph
                sx={{ ml: '1em', fontStyle: 'italic' }}
              >
                noun
              </Typography>
              <Typography variant='h6' align='left' color='text.primary' paragraph
                sx={{ mx: '10px' }}
              >
                1. the recording, reproducing, or broadcasting of moving visual images.<br />
                <i>"Hi, I'm Cas Ewing - I'd love to be your <span style={{ fontSize: '1.1em' }}>video</span> editor! Scroll down to learn more about me."</i>

              </Typography>
            </div>
          </div>

          <Grid id='about-anchor' container spacing={2}>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={0} md={6}>
              <img src={'assets/SarahCityBlack.jpg'} alt={''} width={'100%'}></img>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant='h6' align='left' color='text.secondary' paragraph sx={{ pt: '0.5em', m: '10px' }}>
                Video production, editing, photography, writing.
              </Typography>
              <Typography
                component='h1'
                variant='h2'
                align='left'
                color='text.primary'
                gutterBottom
                sx={{ mx: '10px' }}
              >
                I create video experiences.
              </Typography>
              <Typography variant='h5' align='left' color='text.secondary' paragraph
                sx={{ mx: '10px' }}
              >
                I've had a lifelong passion for connecting people with compelling stories.
                I love the process, and relish
                the outcome: <em>the smile on their face when it hits!</em>
                <br />
                <br />
                Currently I'm seeking a full-time position in video production and editing.
                I'm eager to learn and grow my skill set as part of a team where I can be a key contributor.

              </Typography>
            </Grid>
          </Grid>

        </Container>

        <Container id='video-gallery-anchor' sx={{ py: '2em', marginTop: '2em' }} maxWidth={false}>
          <Typography variant='h4' align='left' sx={{ pt: '0.5em', ml: '10px' }}>
            Portfolio
          </Typography>

          <Typography variant='h5' align='left' color='text.secondary' paragraph sx={{ ml: '10px' }}>
            Take a look at some of my recent work in the selection of videos below, showcasing my range of styles and techniques in video production.
          </Typography>

          <Modal open={!!focusVideoUrl && !matchesSmallScreens} onClose={() => setFocusVideoUrl('')}>
            <Box sx={videoModalStyle}>
              <iframe
                src={focusVideoUrl + '?rel=0&autoplay=1'}
                title={'Video'}
                frameBorder={'0'}
                allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;'}
                allowFullScreen
                width={'100%'}
                height={'100%'}
              >
              </iframe>
            </Box>
          </Modal>
          <Grid container spacing={4}>
            {galleryCards.map((card, index) => {
              return (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      height: '100%', display: 'flex', flexDirection: 'column',
                      '&:hover': {
                        transform: 'scale(1.1)',
                        transition: 'all 0.3s ease-in-out',
                      },
                    }}
                  >
                    <CardActionArea
                      onClick={() => setFocusVideoUrl(card.videoUrl)}
                      sx={{ display: showCardDetail === index ? 'none' : 'block' }}
                    >
                      {/* For now, no thumbnail on small screens - just youtube.
                          Tried overlaying thumbnail image, but had trouble with image sizing when toggling visibility.
                      */}
                      {(matchesSmallScreens) ? (
                        <CardMedia component='iframe'
                          image={card.videoUrl + youtubeMiniParams}
                          frameBorder={'0'}
                          allowFullScreen
                        />
                      ) : (
                        <CardMedia component='img'
                          image={card.thumbnail}
                          alt=''
                        />
                      )}
                    </CardActionArea>
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography gutterBottom variant='h5' component='h2'>
                        {card.heading}
                      </Typography>
                      <Typography>
                        {showCardDetail === index ? card.longDescription : card.brief}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      {card.longDescription ? (
                        <Button size='small' onClick={() => setShowCardDetail(showCardDetail === index ? -1 : index)}>More...</Button>
                      ) : null}
                    </CardActions>
                  </Card>
                </Grid>
              )
            })}
          </Grid>
        </Container>

        <Container maxWidth={false} disableGutters={true}>
          <div id='skills-anchor' style={{ position: 'relative', top: '-3em' }} />
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography variant='h4' align='left' sx={{ pt: '0.5em', ml: '10px' }}>
                Skills and Certifications
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <ul style={{
                color: theme.palette.text.secondary,
                ...theme.typography.h6,
              }}>
                <li>Adobe Premiere Pro (3 years)</li>
                <li>Video Production</li>
                <li>Photography</li>
                <li>Creative Collaboration</li>
                <li>Adobe Creative Suite</li>
                <li>Customer service</li>
              </ul>
            </Grid>
            <Grid item xs={4}>
              <Stack spacing={0}>
                <img src={'assets/AdobeCertifiedPro_Premiere-wht.png'} style={{ margin: 'auto', width: '7em' }} alt={''} ></img>
                <Typography variant='body1' align='center' color='text.secondary' paragraph sx={{ pt: '0.5em', m: '10px' }}>
                  Certified Professional<br />
                  Adobe Premiere Pro
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' align='left' sx={{ ml: '1.5em' }}>
                <a href='assets/Ewing_Resume.pdf' style={{ color: 'lightskyblue' }}>View Resume PDF</a>
              </Typography>
            </Grid>
          </Grid>

        </Container>


      </Box>

    </main>

  )
}

import * as React from 'react'
import Album from './Album'
import Footer from './Footer'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import HomeIcon from '@mui/icons-material/Home'
import InfoIcon from '@mui/icons-material/Info'
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'

export default function App() {
  const [mode] = React.useState<'light' | 'dark'>('dark')
  const [drawerOpen, setDrawerOpen] = React.useState(false)

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  )

  // const toggleColorMode = () => {
  //   setMode(mode === 'dark' ? 'light' : 'dark')
  // }

  const menuList = () => {
    const scrollToSection = (event: React.MouseEvent<HTMLDivElement>, anchorName: string) => {
      const anchor = (
        (event.target as HTMLDivElement).ownerDocument || document
      ).querySelector(anchorName)

      if (anchor) {
        setTimeout(() => {
          // For some reason scrollIntoView() with behavior:'smooth' breaks unless deferred after this event
          anchor.scrollIntoView({
            block: 'start',
            inline: 'start',
            behavior: 'smooth',
          })
        }, 0)
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
    }

    return (
      <Box
        sx={{ width: 250 }}
        role='presentation'
        onClick={() => setDrawerOpen(false)}
        onKeyDown={() => setDrawerOpen(false)}
      >
        <List>
          {[
            { title: 'Home', icon: (<HomeIcon />), handler: scrollToSection, data: '#top-anchor' },
            { title: 'About Me', icon: (<InfoIcon />), handler: scrollToSection, data: '#about-anchor' },
            { title: 'Portfolio', icon: (<OndemandVideoIcon />), handler: scrollToSection, data: '#video-gallery-anchor' },
            { title: 'Skills', icon: (<AutoFixHighIcon />), handler: scrollToSection, data: '#skills-anchor' },
            { title: 'Contact', icon: (<PermContactCalendarIcon />), handler: scrollToSection, data: '#contact-anchor' },
          ].map((item, index) => (
            <ListItem key={item.title} disablePadding>
              <ListItemButton onClick={ev => item.handler(ev, item.data)}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position='sticky'>
        <Toolbar variant='dense'>
          <IconButton sx={{ mr: 2 }} onClick={() => setDrawerOpen(!drawerOpen)}>
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" color="inherit" noWrap>
            Sarah Ewing - Video Editing and Production
          </Typography> */}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor='left'
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false)
        }}
      >
        {menuList()}
      </Drawer>
      <Album />
      <br />
      <Footer />

    </ThemeProvider>
  )
}

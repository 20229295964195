import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

function Copyright() {
  return (
    <Typography variant='body2' color='text.secondary' align='center'>
      {'Copyright © '}
      {'Sarah Ewing, '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

export default function Footer() {
  return (
    <Box id='contact-anchor' sx={{ bgcolor: 'background.paper', p: 2 }} component='footer'>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant='h4' align='left' gutterBottom>
            Let's Connect!
          </Typography>
          <Typography variant='body1' align='left' gutterBottom
            sx={{ mx: '10px' }}
          >
            I currently reside in Madison, Alabama. If you'd like to chat about an opportunity, just
            reach out at the email address below, and I'll respond promptly.
          </Typography>

        </Grid>
        <Grid item xs={6}>
          <img src={'assets/Cam1.jpg'} style={{margin:'auto', width:'40%'}} alt={''}></img>
        </Grid>

        <Grid item xs={12}>
          <hr />
          <Typography
            variant='h6'
            align='left'
            color='text.secondary'
            component='p'
          >
          Contact: <a href='mailto:sarabethewing@gmail.com' style={{color:'whitesmoke'}}>sarabeth.ewing@gmail.com</a>
          </Typography>
        </Grid>

        <Grid item xs={12}>
        </Grid>
      </Grid>
      <Copyright />
    </Box>
  )
}

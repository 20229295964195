// Video overlay for "above the fold" display beneath title screen
import React from 'react'

interface BackgroundVideoProps {
  vidUrl: string
}

const BackgroundVideo = React.forwardRef((props: BackgroundVideoProps, ref:any) => {
  const backgroundVideoStyle = {
    objectFit: 'cover',
    maxHeight: 'calc(100vh - 48px)',  // Hardcoded for 48 pix high app-bar
    minWidth: '100vw',
  } as React.CSSProperties

  const videoContainerStyle = {
    width: '100vw',
    height: '100vh',
  } as React.CSSProperties

  return (
    <div style={videoContainerStyle}>
      <video ref={ref} style={backgroundVideoStyle} autoPlay muted loop>
        <source src={props.vidUrl} type='video/mp4' />
          Your browser does not support the video tag.
      </video>
    </div>
  )
})

export default BackgroundVideo
